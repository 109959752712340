import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormInput, Grid, Spinner } from '@lionstep/ui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classes from './authentication.module.scss';
import * as Yup from 'yup';
import http from '../../http';
import errorDebug from '../../lib/errorDebug';
import { camelToSnakeObj } from '../../lib/common';
import useAuth from '../../hooks/useAuth';
import { useEffect, useState } from 'react';

const SignUpValidatedAccountConfirmToken = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [email, setEmail] = useState();

  const schema = Yup.object().shape({
    password: Yup.string().required(t('rules.required')),
  });

  const { handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const { registerWithToken, isLoading } = useAuth();
  const onSaveChanges = (data) => registerWithToken(camelToSnakeObj({ ...data, token }))
    .then(() => navigate('/', { replace: true }));


  useEffect(() => {
    if (token) {
      http.get('/users/candidates/confirm', { params: { token } })
        .then(({ data }) => setEmail(data))
        .catch(errorDebug);
    }
  }, [token]);

  return (
    <>
      <Grid.Container className={classes.gridContainer}>
        <div className={classes.container}>
          <form>
            <p className={classes.pleaseMessage}>{t('authentication.sign_up_validated_account.registering_confirmation')}</p>
            <div className={classes.marginTop} />
            <FormInput
              label={`${t('authentication.email')}*`}
              name="email"
              error={errors.email?.message}
              disabled={true}
              value={email}
            />
            <FormInput
              label={`${t('authentication.sign_up_validated_account.create_password')}*`}
              name="password"
              type="password"
              placeholder="6+ characters"
              error={errors.password?.message}
              {...register('password')}
            />
          </form>

          <Button className={classes.w100}
            onClick={handleSubmit(onSaveChanges)}
            data-testid="submit-button"
          >
            {t('authentication.sign_up_validated_account.complete_registration')}
          </Button>
        </div>
      </Grid.Container>
      {(!email || isLoading) && <Spinner position="fixed" size={100} />}
    </>
  );
};

export default SignUpValidatedAccountConfirmToken;