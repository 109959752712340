import React from "react";
import { Icon, Dropdown } from "@lionstep/ui";
import { ReactComponent as EnglishFlag } from '../../../assets/icons/flags/english.svg';
import { ReactComponent as GermanFlag } from '../../../assets/icons/flags/deutsch.svg';
import { ReactComponent as SpanishFlag } from '../../../assets/icons/flags/spanish.svg';
import { ReactComponent as FrenchFlag } from '../../../assets/icons/flags/french.svg';
import { useTranslation } from "react-i18next";

import classes from './languagePicker.module.scss';
import cx from 'classnames';

const FLAGS = {
  en: EnglishFlag,
  de: GermanFlag,
  es: SpanishFlag,
  fr: FrenchFlag,
};

const LanguageGroup = ({ isOpen, dark }) => {
  const { i18n } = useTranslation();
  return (
    <div className={classes.languageGroup}>
      <LanguageFlag flagKey={i18n.resolvedLanguage} />
      <Icon className={cx(dark && classes.iconWhite)} name={isOpen ? 'ArrowUp' : 'ArrowDown'} />
    </div>
  );
}

const LanguageFlag = ({ flagKey }) => {
  const Flag = FLAGS[flagKey];
  return <Flag className={classes.languageIcon} />;
}

const LanguagePicker = ({ className, dark }) => {
  const { t, i18n } = useTranslation();
  return <div className={className}>
    <div>
      <Dropdown
        className={cx(classes.languageDropdown, dark && classes.dark)}
        trigger={(props) => <LanguageGroup dark={dark} {...props} />}
      >
        {Object.keys(FLAGS).map(flagKey => (
          <Dropdown.Item
            className={classes.languageDropdownItem}
            onClick={() => i18n.changeLanguage(flagKey)}
            key={flagKey}
          >
            <div className={classes.dropdownOption}>
              <LanguageFlag flagKey={flagKey} />
              <span className={classes.languageName}>{t(`languages_short.${flagKey}`)}</span>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  </div>
}

export default LanguagePicker;