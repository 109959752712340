import React, { Suspense } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { Spinner } from '@lionstep/ui';

import AuthorizedLayout from '../components/layout/authorizedLayout/Layout';
import Home from './home/Home';

import AuthenticationLayout from '../components/layout/authenticationLayout/AuthenticationLayout';
import SignIn from './authentication/SignIn';
import SignUp from './authentication/SignUp';
import SignUpStepTwo from './authentication/SignUpStepTwo';
import SignUpConfirmToken from './authentication/SignUpConfirmToken';
import ResendEmail from './authentication/ResendEmail';

import ForgotPassword from './authentication/ForgotPassword';
import PasswordRecovery from './authentication/PasswordRecovery';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

import Confirm from '../components/common/confirm/Confirm';
import SignUpValidatedAccountConfirmToken from './authentication/SignUpValidatedAccountConfirmToken';

const Main = () => {
  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={<Spinner position="fixed" size={100} />}>
          <Routes>
            <Route path="/" element={<AuthenticationLayout />}>
              <Route exact path="/auth/login" element={<SignIn />} />
              <Route exact path="/auth/signup" element={<SignUp />} />
              <Route exact path="/auth/signup/confirm" element={<SignUpConfirmToken />} />
              <Route exact path="/auth/signup/confirmation-pending" element={<ResendEmail />} />
              <Route exact path="/auth/signup/account-confirmation" element={<SignUpValidatedAccountConfirmToken />} />
              <Route exact path="/account-confirmation" element={<SignUpValidatedAccountConfirmToken />} />
              <Route exact path="/auth/forgot-password" element={<ForgotPassword />} />
              <Route exact path="/auth/password-recovery" element={<PasswordRecovery />} />
              <Route exact path="/auth/linkedin/callback" element={<LinkedInCallback />} />
            </Route>

            <Route path="/" element={<AuthorizedLayout />}>
              <Route index element={<Home />} />
              <Route exact path="/auth/signup/step-two" element={<SignUpStepTwo />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>

      <Confirm />
    </div>
  );
};

function NotFound() {
  return (
    <div>
      <h2>Page not found!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default Main;
