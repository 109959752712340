import classes from './authenticationSplitter.module.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const AuthenticationSplitter = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(classes.splitter, className)}>
      <div />
      <p className={classes.or}>{t('authentication.or')}</p>
      <div />
    </div>
  );
};

export default AuthenticationSplitter;