import { useEffect, useState } from "react";

const getUseSingeltonStateHook = (defaultData) => {
  let dataObj = defaultData;
  let subscribers = [];

  const localSetData = data => {
    dataObj = data;
    subscribers.forEach(setData => setData(data));
  };

  return () => {
    const [data, setData] = useState(dataObj);

    useEffect(() => {
      subscribers = [...subscribers, setData];
      return () => subscribers = subscribers.filter(f => f !== setData);
    }, [setData]);

    return [data, localSetData];
  }
}

export default getUseSingeltonStateHook;
