import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import EN from './translations/en-en.json';
import DE from './translations/de-de.json';
import ES from './translations/es-es.json';
import FR from './translations/fr-fr.json';
global.i18n = i18n;

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: {
        translation: EN,
      },
      de: {
        translation: DE,
      },
      es: {
        translation: ES,
      },
      fr: {
        translation: FR,
      },
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
