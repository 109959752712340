import { Modal } from "@lionstep/ui";
import useConfirm from "../../../hooks/useConfirm";
import classes from './confirm.module.scss';

const Confirm = () => {
  const {
    request: {
      title,
      message,
      confirmText,
      confirmButtonType,
      cancelText,
    },
    resolver: { resolve, reject },
  } = useConfirm();

  return title && (
    <Modal show={true} onClose={reject}>
      <Modal.Header>
        {title}
      </Modal.Header>
      <Modal.Body>
        <div className={classes.body}>
          {message}
        </div>
      </Modal.Body>
      <Modal.Footer
        confirmText={confirmText}
        confirmButtonType={confirmButtonType || 'primary'}
        closeText={cancelText}
        onConfirm={resolve}
        onClose={reject}
      />
    </Modal>
  )
};

export default Confirm;