import { useLocation, useNavigate } from "react-router-dom";
import { LINKEDIN_CLIENT_ID, LINKEDIN_REDIRECT_URL } from "../config";
import useAuth from "./useAuth";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { format } from "../lib/string";

const useLinkedInLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signInLinkedIn } = useAuth();
  const redirectUri = format(LINKEDIN_REDIRECT_URL, { origin: window.origin });

  let from = location.state?.from?.pathname || '/';
  console.log(from);

  const { linkedInLogin } = useLinkedIn({
    clientId: LINKEDIN_CLIENT_ID,
    redirectUri,
    scope: ['r_liteprofile', 'r_emailaddress'],
    onSuccess: (code) => signInLinkedIn(code, redirectUri)
      .then(() => navigate(from, { replace: false })),
  });

  return linkedInLogin;
}

export default useLinkedInLogin;