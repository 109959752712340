import { FormInput, Button } from '@lionstep/ui';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import Title from './Title';

import classes from './accountDetails.module.scss';
import cx from 'classnames';
import { useEffect } from 'react';

const AccountDetails = ({ className, candidate, onUpdate }) => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    firstName: Yup.string().required(t('rules.required')),
    lastName: Yup.string().required(t('rules.required')),
    // phoneNumber: Yup.string().required(t('rules.required')),
  });

  const { handleSubmit, register, formState: { errors }, reset } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => reset(candidate), [reset, candidate]);

  return (
    <div className={cx(className, classes.accountDetails)}>
      <Title>{t('home.account_details.title')}</Title>
      <form>
        <div className={classes.formInput}>
          <FormInput
            label={t('home.account_details.first_name')}
            name="firstName"
            error={errors.firstName?.message}
            {...register('firstName')}
          />
        </div>
        <div className={classes.formInput}>
          <FormInput
            label={t('home.account_details.last_name')}
            name="lastName"
            error={errors.lastName?.message}
            {...register('lastName')}
          />
        </div>
        <div className={classes.formInput}>
          <FormInput
            label={t('home.account_details.phone_number')}
            name="phoneNumber"
            error={errors.phoneNumber?.message}
            {...register('phoneNumber')}
          />
        </div>
        <div className={classes.formInput}>
          <Button
            className={classes.button}
            minWidth={true}
            ghost={true}
            size="medium"
            typeHtml="button"
            onClick={handleSubmit(onUpdate)}
          >{t('home.account_details.update')}</Button>
        </div>
      </form>
    </div>
  );
}

export default AccountDetails;