import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormInput, Gdpr, Grid, Spinner } from '@lionstep/ui';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import useAuth from '../../hooks/useAuth';
import classes from './authentication.module.scss';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import AuthenticationSplitter from './components/AuthenticationSplitter';
import LinkedInButton from './components/LinkedInButton';
import useLinkedInLogin from '../../hooks/useLinkedInLogin';

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn, isLoading } = useAuth();
  const { t } = useTranslation();
  const linkedInLogin = useLinkedInLogin();

  let from = location.state?.from?.pathname || '/';

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t('rules.email_error'))
      .required(t('rules.required')),
    password: Yup.string().required(t('rules.required')),
  });

  const { handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const onSaveChanges = (data) => {
    let user = { ...data };
    signIn(user)
      .then(() => navigate(from, { replace: false }))
      .catch(() => toast.error(t('authentication.sign_in.invalid_credentials'), {
        toastId: 'login-error',  
      }));
  }

  return <>
    <Grid.Container className={classes.gridContainer}>
      <div className={classes.container}>
        <LinkedInButton onClick={linkedInLogin}>{t('authentication.sign_in.login_with_linkedin')}</LinkedInButton>

        <AuthenticationSplitter />

        <form className={classes.marginTop}>
          <FormInput
            label={t('authentication.email')}
            name="email"
            error={errors.email?.message}
            {...register('email')}
          />

          <div className={classes.passwordContainer}>
            <label>{t('authentication.password')}</label>
            <Link
              className={classes.marginLeft}
              to="/auth/forgot-password"
              tabIndex="-1"
              data-testid="forgot-password-link"
            >
              {t('authentication.sign_in.forgot_password')}
            </Link>
          </div>
          <FormInput
            name="password"
            type="password"
            error={errors.password?.message}
            {...register('password')}
          />
        </form>

        <Button className={classes.w100}
          onClick={handleSubmit(onSaveChanges)}
          data-testid="submit-button"
        >
          {t('authentication.sign_in.login')}
        </Button>

        <div className={classes.lastBox}>
          {t('authentication.sign_in.new_to_lionstep')}
          <Link className={classes.marginLeft} to="/auth/signup" data-testid="signup-link">{t('authentication.sign_in.register_here')}</Link>
        </div>

        <Gdpr
          className={classes.termsAndConditions}
          acceptText={t('gdpr.accept')}
          termsText={t('gdpr.terms_and_conditions')}
          acknowledgeText={t('gdpr.acknowledge')}
          policyText={t('gdpr.privacy_policy')}
          fromText={t('gdpr.from')}
        />
      </div>
    </Grid.Container>
    {isLoading && <Spinner position="fixed" size={100} />}
  </>;
}

export default SignIn;