import { Button, Grid } from "@lionstep/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import cx from 'classnames';
import classes from './authentication.module.scss';
import http from "../../http";
import errorDebug from "../../lib/errorDebug";

const SignUpConfirmToken = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const confirmEmail = () => http.post('/users/candidates/resend-email-confirmation')
    .then(() => {
      toast.info(t('authentication.resend_email.success_note'));
      navigate('/auth/login');
    })
    .catch(errorDebug);

  return (
    <Grid.Container className={cx(classes.gridContainer, classes.resendEmail)}>
      <div className={classes.container}>
        <p className={classes.title}>{t('authentication.resend_email.thank_you')}</p>
        <p className={cx(classes.pleaseMessage, classes.marginTop)}>{t('authentication.resend_email.please_confirm')}</p>
        <p className={classes.marginTop}>{t('authentication.resend_email.check_inbox')}</p>
        <div className={cx(classes.confirmButtonContainer, classes.marginTop)}>
          <Button
            className={classes.w100}
            minWidth={false}
            onClick={confirmEmail}
            data-testid="submit-button"
          >
            {t('authentication.resend_email.confirm')}
          </Button>
        </div>
      </div>
    </Grid.Container >
  );
};

export default SignUpConfirmToken;