import { Button, FormInput, Grid } from '@lionstep/ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import classes from './authentication.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import http from '../../http';
import errorDebug from '../../lib/errorDebug';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t('rules.email_error'))
      .required(t('rules.required')),
  });

  const { handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const onResetPassword = ({ email }) =>
    http.post('/users/reset-password', { email, app: 'CandidatePortal' })
      .then(() => {
        toast.info(t('authentication.forgot_password.success_note'));
        navigate('/auth/login');
      })
      .catch(errorDebug);

  return (
    <Grid.Container className={classes.gridContainer}>
      <div className={classes.container}>
        <p className={classes.pleaseMessage}>{t('authentication.forgot_password.please_type_email')}</p>

        <form className={classes.marginTop}>
          <FormInput
            label={`${t('authentication.email')}*`}
            name="email"
            placeholder="janedoe@mail.com"
            error={errors.email?.message}
            {...register('email')}
          />
        </form>
        <Button className={classes.w100}
          onClick={handleSubmit(onResetPassword)}
          data-testid="submit-button"
        >
          {t('authentication.forgot_password.reset_password')}
        </Button>

        <div className={classes.lastBox}>
          {t('authentication.forgot_password.know_your_password')}
          <Link className={classes.marginLeft} to="/auth/login" data-testid="login-link">
            {t('authentication.forgot_password.login')}
          </Link>
        </div>
      </div>
    </Grid.Container>
  )
};

export default ForgotPassword;