import React from "react";
import { Icon, Dropdown } from "@lionstep/ui";
import { ReactComponent as UserIcon } from '../../../../assets/icons/user-1.svg';
import cx from 'classnames';
import classes from './userDropdown.module.scss';
import { useTranslation } from "react-i18next";
import useAuth from "../../../../hooks/useAuth";

const UserGroup = ({ isOpen }) => {
  return (
    <div className={classes.userGroup}>
      <UserIcon className={classes.userIcon} />
      <Icon className={classes.iconWhite} name={isOpen ? 'ArrowUp' : 'ArrowDown'} />
    </div>
  );
}

const UserDropdown = ({ className }) => {
  const { user, signOut } = useAuth();
  const { t } = useTranslation();
  return <div className={className}>
    <div>
      <Dropdown className={classes.userDropdown} trigger={(props) => <UserGroup {...props} />}>
        <li className={classes.loggedInText}>{t('layout.user_dropdown.you_are_logged_in_as')}</li>
        <Dropdown.Item className={cx(classes.dropdownItem, classes.user)}>
          {user?.email}
        </Dropdown.Item>
        <Dropdown.Item className={classes.dropdownItem} onClick={signOut}>
          {t('layout.user_dropdown.logout')}
        </Dropdown.Item>
      </Dropdown>
    </div>
  </div>
}

export default UserDropdown;