import LanguagePicker from '../languagePicker/LanguagePicker';
import { ReactComponent as Logo } from '../../../assets/icons/lionstep-logo.svg';

import classes from './authenticationHeader.module.scss';

const AuthenticationHeader = () => (
  <header className={classes.header}>
    <div className={classes.fixed}>
      <div>
        <div className={classes.logo}>
          <Logo className={classes.logo} />
        </div>
      </div>
      <LanguagePicker />
    </div>
  </header>
);

export default AuthenticationHeader;