import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormInput, Grid, Spinner } from '@lionstep/ui';
import { Navigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import classes from './authentication.module.scss';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
// import http from '../../http';

import cx from 'classnames';
// import errorDebug from '../../lib/errorDebug';
import { useFetchCandidateQuery, usePutCandidateQuery } from '../home/home.queries';
import { useEffect } from 'react';

const defaultValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  linkedinUrl: '',
}

const SignUpStepTwo = () => {
  const { t } = useTranslation();
  const { data: candidate } = useFetchCandidateQuery();
  const { mutate: putCandidate } = usePutCandidateQuery();

  const schema = Yup.object().shape({
    firstName: Yup.string().required(t('rules.required')),
    lastName: Yup.string().required(t('rules.required')),
    phoneNumber: Yup.string().required(t('rules.required')),
  });

  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (candidate) {
      const candidateData = Object.keys(defaultValues)
        .reduce((acc, key) => ({ ...acc, [key]: candidate[key] }), {});
      reset(candidateData);
    }
  }, [reset, candidate]);

  if (!candidate)
    return <Spinner position="fixed" size={100} />

  if (candidate.hasPiModel)
    return <Navigate to="/" replace />;

  return (
    <>
      <Grid.Container className={classes.gridContainer}>
        <div className={classes.container}>
          <form className={classes.marginTop}>
            <FormInput
              label={`${t('authentication.sign_up_step_two.first_name')}*`}
              name="firstName"
              placeholder="Jane"
              error={errors.firstName?.message}
              {...register('firstName')}
            />
            <FormInput
              label={`${t('authentication.sign_up_step_two.last_name')}*`}
              name="lastName"
              placeholder="Doe"
              error={errors.lastName?.message}
              {...register('lastName')}
            />
            <FormInput
              label={`${t('authentication.sign_up_step_two.phone_number')}*`}
              name="phoneNumber"
              placeholder="0041 78 000 000"
              error={errors.email?.message}
              {...register('phoneNumber')}
            />
            <FormInput
              label={`${t('authentication.sign_up_step_two.linkedin_url')}`}
              name="linkedinUrl"
              placeholder="http://linkedin.com/your-name"
              error={errors.email?.message}
              {...register('linkedinUrl')}
            />

            <Button
              className={cx(classes.w100)}
              onClick={handleSubmit(putCandidate)}
              data-testid="submit-button"
            >
              {t('authentication.sign_up_step_two.save')}
            </Button>
          </form>
        </div>
      </Grid.Container>
    </>
  );
}

export default SignUpStepTwo;