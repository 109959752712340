import { ReactComponent as LogoIcon } from '../../../assets/icons/document-illustration.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/escape-small.svg';
import UploadProgressBar from './UploadProgressBar';

import classes from './documentFile.module.scss';
import cx from 'classnames';
import { useState } from 'react';

const DocumentFile = ({ className, attachment, url, uploadProgress, error, onRemove }) => {
  const [isXHovered, setIsXHovered] = useState(false);
  return (
    <div className={cx(classes.documentFile, isXHovered && classes.delete, className)}>
      <div className={classes.container}>
        <LogoIcon className={classes.fileIcon} />
        <div className={classes.labelContainer}>
          <a className={classes.label} download={attachment} href={url} target="_blank" rel="noreferrer">
            <div className={classes.truncate}>
              {attachment}
            </div>
          </a>
          {uploadProgress &&
            <UploadProgressBar uploadProgress={uploadProgress} error={error} />}
        </div>
      </div>
      {onRemove &&
        <DeleteIcon
          className={cx(classes.deleteIcon, isXHovered && classes.delete)}
          onClick={onRemove}
          onMouseEnter={() => setIsXHovered(true)}
          onMouseLeave={() => setIsXHovered(false)} />}
    </div>
  );
}

export default DocumentFile;