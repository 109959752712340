import { useCallback, useEffect } from "react";
import getUseSingeltonStateHook from "../lib/getUseSingeltonStateHook";
const useRequestState = getUseSingeltonStateHook({});
const useResolverState = getUseSingeltonStateHook({});

const useConfirm = () => {
  const [request, setRequest] = useRequestState();
  const [resolver, setResolver] = useResolverState();

  const clearAll = useCallback(() => {
    setRequest({});
    setResolver({});
  }, [setRequest, setResolver]);

  const confirm = useCallback((requestObject) => {
    const promise = new Promise((resolve, reject) => {
      setResolver({
        resolve: () => { resolve(); clearAll(); },
        reject: () => { reject(); clearAll(); }
      });
    });

    setRequest(requestObject);
    return promise;
  }, [setRequest, setResolver, clearAll]);

  useEffect(() => clearAll, [clearAll]);

  return {
    request,
    resolver,
    confirm,
  };
};

export default useConfirm;