import { Button } from '@lionstep/ui';
import { useTranslation } from 'react-i18next';
import Title from './Title';
import classes from './personalityTest.module.scss';
import DocumentFile from './DocumentFile';
import { useCandidateOptionsQuery } from '../home.queries';

const PersonalityTest = ({ className, personalityTest }) => {
  const { t } = useTranslation();
  const { url, document } = personalityTest;
  const { data: options } = useCandidateOptionsQuery();

  return (
    <div className={className}>
      <Title>{t('home.personality-test.title')}</Title>

      {!document &&
        <a className={classes.link} href={url} target="_blank" rel="noopener noreferrer">
          <Button>
            {t('home.personality-test.complete_test')}
          </Button>
        </a>}

      {document &&
        <DocumentFile
          attachment={document.originalFilename}
          url={`${options?.bucket}${document.attachment}`}
        />}
    </div>
  );
}

export default PersonalityTest;