
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Main from './pages/Main';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Main />
        {process.env.REACT_APP_NODE_ENV === 'development' && (
          <ReactQueryDevtools />
        )}
      </QueryClientProvider>
      <ToastContainer theme="colored" />
    </div>
  );
}

export default App;
