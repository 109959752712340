import { camelToSnake, snakeToCamel } from "./string";

export const isFunction = value => typeof value === 'function';
export const isObject = value => value !== null && typeof value === 'object';
export const isString = value => typeof value === 'string' || value instanceof String;
export const isDate = value => value instanceof Date;

export const propToBool = prop => prop === '' || prop;
export const propToFnc = prop => (isFunction(prop) ? prop : (f => (prop ? f[prop] : f)));
export const valueToFnc = value => isFunction(value) ? value : (() => value);

export const tryParse = obj => {
  try {
    return JSON.parse(obj);
  } catch (e) {
    return null;
  }
}

const caseObj = (obj, caseFnc) => {
  if (Array.isArray(obj)) {
    return obj.map(f => caseObj(f, caseFnc));
  } else if (isDate(obj)) {
    return obj;
  } else if (isObject(obj)) {
    return Object.keys(obj)
      .reduce((acc, key) => {
        acc[caseFnc(key)] = (obj[key] && isObject(obj[key])) ? caseObj(obj[key], caseFnc) : obj[key];
        return acc;
      }, {});
  } else return obj;
}

export const snakeToCamelObj = obj => caseObj(obj, snakeToCamel);
export const camelToSnakeObj = obj => caseObj(obj, camelToSnake);