import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import errorDebug from "../../lib/errorDebug";
import useAuth from "../../hooks/useAuth";
import { Spinner } from '@lionstep/ui';

const SignUpConfirmToken = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { confirm } = useAuth();

  useEffect(() => {
    if (token) {
      confirm(token)
        .then(() => {
          toast.info(t('authentication.sign_up_confirm.success_note'));
          navigate('/', { replace: true });
        })
        .catch(errorDebug);
    }
  }, [token, t, navigate, confirm]);

  return (
    <Spinner position="fixed" size={100} />
  );
};

export default SignUpConfirmToken;