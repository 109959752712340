import React from "react";
import classes from './header.module.scss';
import { ReactComponent as LogoDesktop } from '../../../assets/icons/lionstep-logo.svg';
import { ReactComponent as LogoMobile } from '../../../assets/icons/lionstep-logo-white.svg';
import LanguagePicker from "../languagePicker/LanguagePicker";
import UserDropdown from "./userDropdown/UserDropdown";

const Header = () => (
  <header className={classes.header}>
    <div className={classes.fixed}>
      <LogoDesktop className={classes.logoDesktop} fill="white" />
      <LogoMobile className={classes.logoMobile} fill="white" />
      <div className={classes.container}>
        <LanguagePicker className={classes.language} dark={true} />
        <UserDropdown className={classes.user} />
      </div>
    </div>
  </header>
);

export default Header;