import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import Header from "./Header";
import { useEffect } from "react";

const Layout = () => {
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    document.body.classList.remove('authentication');
    document.body.classList.add('authorized');
  }, []);

  if (!user) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  } else if (!user.emailVerified) {
    return <Navigate to="/auth/signup/confirmation-pending" state={{ from: location }} replace />;
  }

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}

export default Layout;
