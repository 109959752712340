import { ReactComponent as Logo } from '../../../assets/icons/lionstep-logo.svg';

import classes from './authenticationTitle.module.scss';
import { useTranslation } from "react-i18next";

const AuthenticationTitle = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.logoBox}>
      <Logo className={classes.logo} />
      <p className={classes.title}>{t('layout.title')}</p>
    </div>
  );
}
export default AuthenticationTitle;