import { Outlet } from "react-router-dom";
import AuthenticationHeader from "./AuthenticationHeader";
import AuthenticationTitle from "./AuthenticationTitle";
import { useEffect } from "react";

const AuthenticationLayout = () => {
  useEffect(() => {
    document.body.classList.remove('authorized');
    document.body.classList.add('authentication');
  }, []);

  return (
    <>
      <AuthenticationHeader />
      <AuthenticationTitle />
      <Outlet />
    </>
  );
}
export default AuthenticationLayout;