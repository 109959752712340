import { isObject } from "./common";

export const format = (str, ...args) => {
  if (args.length) {
    var keyVal = (args.length === 1 && isObject(args[0])) ? Object.entries(args[0]) : args.map((f, ind) => [ind, f]);
    return keyVal.reduce((acc, [key, value]) => acc.replace(new RegExp(`\\{${key}\\}`, 'gi'), value), str);
  }
  return str;
};

export const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const snakeToCamel = str => str.toLowerCase().split('_').map((f, ind) => ind ? capitalize(f) : f).join('');
export const camelToSnake = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`).replace(/^_/g, '');
