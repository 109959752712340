import http from '../http';
import { snakeToCamelObj } from '../lib/common';
import getUseSingeltonStateHook from '../lib/getUseSingeltonStateHook';
import errorDebug from '../lib/errorDebug';
import { parseJwt } from '../lib/jwt';

const getUserData = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const { user } = parseJwt(token);
    return user && {
      ...snakeToCamelObj(user),
      email: localStorage.getItem('email'),
    };
  }
}

const setUserData = ({ data }) => {
  var { token, email } = snakeToCamelObj(data);
  localStorage.setItem('email', email);
  localStorage.setItem('token', token);
  localSetUser(getUserData());
}

const signInLinkedIn = (code, redirectUri) => {
  localSetIsLoading(true);
  return http.get(`/users/authorize/candidate/linkedin?code=${code}&redirect_url=${redirectUri}`)
    .then(setUserData)
    .catch(errorDebug)
    .then(() => localSetIsLoading(false));
}

const signIn = user => {
  localSetIsLoading(true);
  return http.post('/users/candidates/login', { ...user, role: 'Candidate' })
    .then(setUserData)
    .finally(() => localSetIsLoading(false));
}

export const signOut = () => {
  localSetUser(null);
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

const confirm = token => {
  localSetIsLoading(true);
  return http.post('/users/candidates/confirm', { token })
    .then(setUserData)
    .catch(errorDebug)
    .then(() => localSetIsLoading(false));
}

const registerWithToken = data => {
  localSetIsLoading(true);
  return http.post(`/users/candidates/register-with-token`, data)
    .then(setUserData)
    .catch(errorDebug)
    .then(() => localSetIsLoading(false));
}

let localSetUser = () => null;
let localSetIsLoading = () => null;

let userFromStorage = getUserData();

if (userFromStorage && !userFromStorage.emailVerified) {
  userFromStorage = null;
}

const useUserState = getUseSingeltonStateHook(userFromStorage);
const useLoadingState = getUseSingeltonStateHook(false);

const useAuth = () => {
  const [user, setUser] = useUserState();
  const [isLoading, setIsLoading] = useLoadingState();

  localSetUser = setUser;
  localSetIsLoading = setIsLoading;

  return {
    user,
    signIn,
    signInLinkedIn,
    signOut,
    confirm,
    registerWithToken,
    isLoading,
  };
}

export default useAuth;