import axios from 'axios';
import { PYTHON_API } from './config';
import { signOut } from './hooks/useAuth';

// REQUEST INTERCEPTOR
const createRequestInterceptopResolve = (config) => {
  const token = localStorage.getItem('token');
  const commonHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...(token && { 'Authorization': `Bearer ${token}` })
  };

  return {
    ...config,
    headers: {
      ...commonHeaders,
      ...config.headers,
    },
  };
};

const createRequestInterceptopReject = (error) => Promise.reject(error);

// RESPONSE INTERCEPTOR
const createResponseInterceptorResolve = (response) => response;
const createResponseInterceptorReject = (error) => {
  if (error?.response?.status === 401) {
    signOut();
    return Promise.resolve();
  }
  return Promise.reject(error);
};

// Instances
const http = axios.create({
  baseURL: `${PYTHON_API}/v1`,
});
http.interceptors.request.use(
  createRequestInterceptopResolve,
  createRequestInterceptopReject,
);
http.interceptors.response.use(
  createResponseInterceptorResolve,
  createResponseInterceptorReject,
);

export default http;