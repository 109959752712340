import classes from './home.module.scss';

import { ReactComponent as LogoIcon } from '../../assets/icons/lionstep-logo-white.svg';
import { Grid, Spinner } from "@lionstep/ui";
import { useTranslation } from 'react-i18next';

import AccountDetails from "./components/AccountDetails";
// import ApplicationProcess from './components/ApplicationProcess';
import PersonalityTest from './components/PersonalityTest';
import Documents from './components/Documents';
import { useFetchCandidateQuery, useUpdateCandidateQuery, } from './home.queries';
import { Navigate } from 'react-router-dom';


const Home = () => {
  const { t } = useTranslation();

  const { data: candidate, isLoading: isLoadingCandidate } = useFetchCandidateQuery();

  const { mutate: updateCandidate } = useUpdateCandidateQuery();

  if (isLoadingCandidate)
    return <Spinner position="fixed" size={100} />

  if (candidate && !candidate.hasPiModel)
    return <Navigate to="/auth/signup/step-two" replace />;

  return (
    <Grid.Container className={classes.gridContainer}>
      <div className={classes.box}>
        <div className={classes.titleContainer}>
          <div className={classes.circle}>
            <LogoIcon />
          </div>
          <div className={classes.marginLeft}>
            <div className={classes.largeTitle}>{candidate?.firstName} {candidate?.lastName}</div>
          </div>
        </div>
        <div className={classes.splitter} />
        <div className={classes.welcomeText}>
          <p className={classes.lightBold}>{t('home.home.dear')} {candidate?.firstName},</p>
          <p>{t('home.home.welcome_text_1')}</p>
          <p>{t('home.home.welcome_text_2')}</p>
          <p>{t('home.home.welcome_text_3')}</p>
          <p>{t('home.home.welcome_text_4')} <a href="mailto:info@lionstep.com">info@lionstep.com</a>.</p>
          <p className={classes.mb0}>{t('home.home.welcome_text_5')}
            <br />
            {t('home.home.welcome_text_6')}
          </p>
        </div>
      </div>

      {/* <ApplicationProcess className={classes.box} /> */}

      <AccountDetails className={classes.box} candidate={candidate || {}} onUpdate={updateCandidate} />

      {candidate?.personalityTest &&
        <PersonalityTest className={classes.box} personalityTest={candidate.personalityTest} />}

      <Documents className={classes.box} />

    </Grid.Container>
  );
};

export default Home;