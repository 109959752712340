import { Button } from '@lionstep/ui';
import { ReactComponent as LinkedInIcon } from '../../../assets/icons/linkedin-ln.svg';

import classes from './linkedInButton.module.scss';
import cx from 'classnames';

const LinkedInButton = ({ className, onClick, children }) => (
  <Button
    className={cx(classes.linkedInButton, className)}
    onClick={onClick}
    data-testid="linkedin-button"
  >
    <div className={classes.content}>
      <LinkedInIcon />
      {children}
      <span />
    </div>
  </Button>
);

export default LinkedInButton;